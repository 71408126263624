.header {
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.3s, color 0.3s;
}

.header .navbar-nav .nav-link {
  font-weight: bold;
  font-family: 'Inter';
  transition: color 0.3s;
}

.header .nav-link:focus,
.header .nav-link:hover {
  color: #a2a2a0 !important;
}

.header .navbar-nav .nav-link {

  font-weight: bold;
  font-family: 'Inter';
}

.header .nav-link:focus,
.header .nav-link:hover {
  color: #a2a2a0;
}

.header .mega-dropdown .dropdown-menu {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  background-color: #202020;
  top: 100%;
  color: #623c25 !important;
}

.header .mega-dropdown {
  position: static !important;
}

.header .mega-menu .row {
  margin: 0;
}

.header .dropdown-menu.show {
  display: block !important;
}

.header .dropdown-item {
  padding: 10px 20px;
  font-weight: 700;
  font-size: 20px;
  color: #623c25 !important;
  font-family: 'Inter';
}

.header .dropdown-item:hover,
.header .dropdown-item:active,
.header .dropdown-item:focus {
  background-color: #202020;
  color: #00aeef !important;
  border-radius: 10px;
  width: fit-content;
}

.header .navbar-nav .nav-link.active,
.header .navbar-nav .nav-link.show {
  color: #a2a2a0;
}

.header .second-dropdown .dropdown-menu {
  background-color: #202020;
  color: #623c25;
}

.header .navbar-toggler {
  background-color: #623c25;
}

.offcanvas {
  background-color: #202020 !important;
  color: #623c25 !important;
}

.navbar-nav .dropdown-menu {
  background: #202020 !important;
}

.offcanvas .dropdown-item {
  color: #623c25 !important;
  padding: 10px 5px;
}

.offcanvas-header .btn-close {
  color: #623c25;
}

.mega-dropdown .dropdown-toggle::after {
  display: none !important;
}

.second-dropdown .dropdown-toggle::after {
  display: none !important;
}

.navbar-nav .dropdown-menu {
  border: none;
}

.navbar-offconvas .offcanvas-header .btn-close {
  background-color: #623c25 !important;
  border: none !important;
}







/* ---------------------------------------footer------------------------------- */
.footer{
  position: relative;
  overflow: hidden;
}
.footer::before{
    font-family: 'Inter';
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(https://media.canva.com/v2/image-resize/format:PNG/height:133/quality:100/uri:s3%3A%2F%2Fmedia-private.canva.com%2FLGkEY%2FMAGKhKLGkEY%2F1%2Fp.png/watermark:F/width:200?csig=AAAAAAAAAAAAAAAAAAAAAKvs3DctoWPJsXkz7BeA1sHJwlBjG6sdEPnX9fwVKTtX&exp=1720613894&osig=AAAAAAAAAAAAAAAAAAAAAG8UdMJ0SI8erhJZyooY1Cy0rzt-Bzuy8B_SymirRzy5&signer=media-rpc&x-canva-quality=thumbnail);
    background-size: cover;
    background-repeat: no-repeat;
    transform: scale(1, -1);
    z-index: -1;
}

.footer h4,
.footer .first-col h4 {
  color: black;
}

.footer p,
.first-col p {
  color: #292966;
}

.footer .icon-section {
  color: #292966;
}

.footer a:hover,
.footer .icon-section .fa-icon:hover {
  color: #623c25;
}

.footer :link,
.footer a {
  text-decoration: none;
  color: #292966;
  margin-top: 10px;
}