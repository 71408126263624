.banner-section2 {
  color: white;
  margin-top: -90px;
}

/* .banner-section2 .row-section1 .btn {
  background-color: #00aeef;
  border: none;
} */
.banner-section2 {
  background-image: url('../Images/bg1.PNG') ;
   /* background-image: url(../Images/bg1.PNG); */
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-section2 .row-section1 .col-1 {
  /* padding: 120px !important; */
margin-top: 150px !important;
}

.banner-section2 .row-section1 .btn:hover,
.banner-section2 .row-section1 .btn:focus,
.banner-section2 .row-section1 .btn:active {
  border: none;
  background-color: orange;
}

.banner-section2 .heading1 {
  text-transform: uppercase;
  color: #292966;

}

.banner-section2 .btn {
  width: 250px;
  height: 70px;
  font-size: 25px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  background: #00aeef;
  border: none;
  cursor: pointer;
}

.banner-section2 .btn::after {
  content: "";
  position: absolute;
  top: -100%;
  left: -200%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%);
  transform: rotate(30deg);
  animation: shine 1.5s infinite linear;
}

@keyframes shine {
  0% {
    top: -100%;
    left: -200%;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    top: 100%;
    left: 200%;
    opacity: 0;
  }
}


.banner-section2 .fa-Icon {
  font-weight: bold !important;
}

/*-----keyframes-----*/
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



.banner-section2 .row-section1 .heading1 {
  font-weight: 900;
}

.video-background-container {
  position: relative;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

}


/*.video-background-container .row-section1 {
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  color: white;
 }*/
.cards-container-section2 {
  position: relative;
  overflow: hidden;
}

.cards-container-section2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../Images/bg1.PNG);
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1, -1);
  z-index: -1;
}
.cards-container-section2 .card{
  background-color: none !important;
}
.cards-container-section2 .card .card-img-top{
  height: 250px;
  border-radius: 50px 50px 0px 0px;
}

.cards-container-section2 .card-body {

  /* background-image: linear-gradient(to bottom right, #00bfad, #99a3d4); */
  border: none;
  color: white;
  background-color: #e3e5e3;
  border-radius: 0px 0px 50px 50px;

}

.cards-container-section2 .line {
  border: 1px solid #82c1bb;
  position: absolute;
  bottom: 130px;
  width: 100%;
  margin: 0px -16px;

}

.cards-container-section2 .btn-class {
  position: absolute;
  bottom: 50px;
}

.cards-container-section2 .btn {
  background-color: #e3e5e3 !important;
  border: 2px solid orange;
  color: black;
  font-weight: 600;

}
.cards-container-section2 .btn:hover,
.cards-container-section2 .btn:focus,
.cards-container-section2 .btn:active {
  background-color: #e3e5e3 !important;
  border: 2px solid orange !important;
  color: black !important;
  font-weight: 600;
}

.cards-container-section2 .card-title {
  font-size: 20px;
  color: #0f556e;
  font-weight: bold;
  height: 90px;
}

.cards-container-section2 .card-text {
  font-size: 18px;
  color: #08060385;
  height: 90px;

}

/* .speciality-section2 */
.speciality-section2 {
  background-image: url(../Images/bg1.PNG);
  background-size: cover;
  background-repeat: no-repeat;
}
.speciality-section2 p{
color: #30556e;
}

.speciality-section2 .row-section1 .video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.speciality-section2 {
  position: relative;
}



/* .speciality-section2 .row-section1 .heartimg {
  position: absolute;
  bottom: 60%;
  width: 100px;
  height: 50px;
  right: 32%;

} */

/* .speciality-section2 .row-section1 .heartimg {
  position: absolute;
  bottom: 63%;
  width: 133px;
  height: 89px;
  right: 27%;

}

.speciality-section2 .row-section1 .img1 {
  position: absolute;
  left: 20%;
  top: 8%;
}

.speciality-section2 .row-section1 .img2 {
  position: absolute;
  bottom: 24%;
  right: 15%;

} */

.speciality-section2 .row-section1 video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  /* z-index: -1; */
}

.speciality-section2 .row-section1 .text-container {
  position: relative;
  color: white;
  text-align: center;
}


.speciality-section2 .row-section1 .heading1 {
  font-weight: 900;
  color: #292966;

}

.speciality-section2 .row-section2 .col-section1 {
  position: relative;
  overflow: hidden;
}



.speciality-section2 .row-section2 .img-wrapper:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, .3);
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 1;
}

.speciality-section2 .row-section2 .img-wrapper:hover:after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.speciality-section2 .row-section2 .btn {
  background: none !important;
  border: 2px solid #00aeef;
}

.speciality-section2 .row-section2 .btn {


  align-items: center;

  color: inherit;
  font-weight: 500;

  cursor: pointer;
  text-transform: uppercase;
  width: 150px;
  border: 2px solid #00aeef;
}




.speciality-info-section .row-section4 .arrows {
  display: flex;
  justify-content: left;
  position: relative;

}





/* .strengths-list-section2 */

.strengths-list-section2 {
  position: relative;
  overflow: hidden;
}
.strengths-list-section2 .heading1{
  color: #292966;
}

.strengths-list-section2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../Images/bg1.PNG);
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1, -1);
  z-index: -1;
}

.strengths-list-section2 .heading1,
.strengths-list-section2 h1 {
  font-weight: 900;
}

.strengths-list-section2 .row-section1 .section1-col2 {
  position: relative;
  overflow: hidden;
}

.strengths-list-section2 .row-section1 .img-wrapper:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, .3);
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 1;
}

.strengths-list-section2 .row-section1 .img-wrapper:hover:after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;

}



/* testimonial-slider2 */
.testimonial-slider2 {
  background-image: url(../Images/bg1.PNG);
  background-size: cover;
  background-repeat: no-repeat;
}
.testimonial-slider2 h1{
color: #292966;
}
.testimonial-slider2 .text {
  font-family: 'Inter';
}

.testimonial-slider2 .name {
  font-family: 'Inter';
}

.testimonial-slider2 .arrows {
  display: flex;
  justify-content: left;
  position: relative;

}

.testimonial-slider2 .bubble-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.testimonial-slider2 .bubble {
  position: absolute;
  bottom: -50px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: float 10s linear infinite;
}

.testimonial-slider2 h1 {
  font-weight: 900;
}

.testimonial-slider2 .svg-inline--fa{
  color: #292966 !important;
  border: 2px solid #292966;
  border-radius: 50%;
  padding: 3px 8px;
}

@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

/* servicessection  (Servicessection.js) */
/* .servicessection {
        background-image: url(https://webdesign-finder.com/valeo_v2/wp-content/uploads/2019/05/Depositphotos_31105127_original.jpg);
        background-position: bottom center;
        background-size: cover;
        position: relative;
        color: white;
      } */
.servicessection h1,
.servicessection .icon {
  color: #73d0f4;
}

.servicessection .service-label {
  color: #c3d7e1;
}

/* .servicessection:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0.8;
        z-index: 1;
      } */
.servicessection>* {
  position: relative;
  z-index: 2;
}

.servicessection .service-data-section {
  border: 2px dashed;
  border-radius: 10px;
}

/*-------more-info------*/
.more-info .row-section4 a {
  color: #00aeef;
}

.more-info .row-section4 a:hover {
  color: orange;
}               

/* ---------@media screen(min-width: 768px) ----------*/

@media screen and (min-width: 768px) {
  .banner-section2 .row-section1 .heading1 {
    font-size: 100px;
  }

  .banner-section2 .row-section1 p {
    font-size: 20px;
  }

  /* -----.speciality-section2 ----*/
  .speciality-section2 .row-section1 .heading1 {
    font-size: 100px;
  }

  .speciality-section2 p {
    font-size: 24px;
  }

  .speciality-section2 .design-pattern {
    position: absolute;
    left: -300px;
    width: 544px;
    height: 544px;
    background: #00aeef;
    border-radius: 50%;
    animation: zoom-fade 8s linear infinite;
    /* z-index: -1; */
  }

  @keyframes zoom-fade {
    0% {
      transform: scale(.9);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(.9);
    }
  }

  .speciality-section2 .row-section2 .design-pattern2 {
    position: absolute;
    right: -320px;
    width: 544px;
    height: 544px;
    top: 320px;
    background: #f8931e;
    border-radius: 50%;
    animation: zoom-fade 8s linear infinite;
    z-index: -1;
  }

  @keyframes zoom-fade {
    0% {
      transform: scale(.9);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(.9);
    }
  }

  .speciality-section2 .row-section1 .heartimg {
    position: absolute;
    bottom: 63%;
    width: 133px;
    height: 89px;
    right: 27%;

  }

  .speciality-section2 h1 {
    font-weight: 900;
  }

  .speciality-section2 .row-section1 .img1 {
    position: absolute;
    left: 20%;
    top: 8%;
  }

  .speciality-section2 .row-section1 .img2 {
    position: absolute;
    bottom: 24%;
    right: 15%;

  }

  /* speciality-info-section */
  .speciality-info-section .row-section1 .heading1 {
    font-size: 100px;
  }

  .speciality-info-section .row-section3 .heading1,
  .speciality-info-section .row-section5 .heading1 {
    font-size: 80px;
  }

  .speciality-info-section .row-section4 .heading4 {
    font-size: 90px;
  }

  .speciality-info-section .row-section4 .text1 {
    font-size: 30px;
  }

  .speciality-info-section p,
  .speciality-info-section li {
    font-size: 23px;
  }

  .speciality-info-section .row-section4 .arrows {
    font-size: 30px;
  }


  /* ----.strengths-list-section2---- */
  .strengths-list-section2 .heading1 {
    font-size: 100px;
  }

  .strengths-list-section2 .span-text {
    font-size: 23px;
  }

  /*-------testimonial-slider2----*/
  .testimonial-slider2 .text {
    font-size: 25px;
  }

  .testimonial-slider2 .arrows {
    font-size: 25px;
  }

  .testimonial-slider2 .heading1 {
    font-size: 100px;
  }


  /* ---------servicessection  (Servicessection.js) */

  .servicessection h1 {
    font-size: 100px;
  }

  /*-------more-info------*/
  .more-info h1 {
    font-size: 100px;
  }

  .more-info h2 {
    font-size: 90px;
  }

  .more-info .heading,
  .more-info p {
    font-size: 23px;
  }
}

/* ---------@media screen(max-width: 991px) ----------*/
@media (max-width: 991px) {
  .speciality-section2 .row-section1 .img1,
  .speciality-section2 .row-section1 .img2,
  .speciality-section2 .row-section1 .heartimg {
    display: none;
  }
  .cards-container-section2 .cards-row .card-wrapper,
  .cards-container-section2 .cards-row .card-body {
    height: 350px;
  }
}