
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700&display=swap');

body{
/*  
    background-image: linear-gradient(-225deg,
    rgb(138 224 225) 0%,
    rgb(169 199 224) 29%,
    rgb(185 233 225) 67%,
    rgb(237 227 226) 100%); */
color: black;
background-repeat: no-repeat;
overflow-x: hidden;
}